:root {
  --neutral-color: #9aadbf;
  --primary-color: #6d98ba;
  --secondary-color: #3d2c2e;
  --tertiary-color: #657153;
  --success-color: #c5e6a6;
  --error-color: #ee6352;
  --primary-font-color: var(--secondary-color);

  --body-padding: 24px;
  /* --secondary-font-color: var(--neutral-color); */
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  /* color: var(--primary-font-color); */
}
